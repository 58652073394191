import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../../../const';
import { AppContainer, AppButton, PostIcon } from '../../../components';
import { authProvider } from '../../../utils';
import { Paginator, PostType } from '../../../types';
import { PostCard } from './PostCard';
import { BlogWrapper, TagContainer, Tag } from './styled';

export const BlogPage = () => {
  const [allPosts, setAllPosts] = useState<PostType[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [likedPostsIds, setLikedPostsIds] = useState<string[]>([]);

  const { token, profile, isAuth } = authProvider();
  const nav = useNavigate();

  const getPosts = async () => {
    let API_URL = `${API}/posts?page=${currentPage}`;
    if (selectedTag) {
      API_URL += `&tags=${selectedTag}`;
    }

    try {
      const response = await fetch(API_URL);
      if (response.status === 200) {
        const data: Paginator = await response.json();
        if (currentPage === 1) {
          setAllPosts(data.posts);
          setTotalPages(Math.ceil(data.total / 10));
        } else {
          setAllPosts((prev) => [...prev, ...data.posts]);
        }
        if (isAuth()) {
          const ids = data.posts.map((post) => post.id);
          getLikedPostsIds(ids);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTags = async () => {
    try {
      const response = await fetch(`${API}/tags`);
      if (response.status === 200) {
        const data: string[] = await response.json();
        setTags(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLikedPostsIds = async (ids: string[]) => {
    try {
      const response = await fetch(`${API}/profile/likes`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ids),
      });
      if (response.status === 200) {
        const data: string[] = await response.json();
        setLikedPostsIds((prev) => [...prev, ...data]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setLike = async (postId: string) => {
    try {
      const response = await fetch(`${API}/posts/${postId}/likes`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const likes = await response.json();
        const currentPost = allPosts.find((post) => post.id === postId);
        if (currentPost) {
          currentPost.likes = likes;
          const indexOfPost = allPosts.indexOf(currentPost);
          const newAllPosts = allPosts.concat();
          newAllPosts.splice(indexOfPost, 1, currentPost);
          setAllPosts(newAllPosts);
          setLikedPostsIds((prev) => [...prev, currentPost.id]);
        }
      }
    } catch (e) {}
  };

  const openPost = (e: SyntheticEvent<HTMLDivElement>) => {
    const currentAlias = (e.currentTarget as Element).id;
    nav(`${currentAlias}`);
  };

  const onScrollList = (e: SyntheticEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const scrollBottom = target.scrollTop + target.offsetHeight + 0.5 === target.scrollHeight;

    if (scrollBottom && totalPages && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const onTagClick = async (e: SyntheticEvent<HTMLDivElement>) => {
    setCurrentPage(1);
    setSelectedTag((e.target as Element).id);
  };

  const onLikeClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.id;
    setLike(id);
  };

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, currentPage]);

  return (
    <AppContainer title="Лента новостей" hidePanel={!isAuth()}>
      {profile?.is_manager && (
        <Link to="/newpost">
          <AppButton title="Создать запись" fullWidth bold icon={<PostIcon color="#FFF" />} />
        </Link>
      )}

      <BlogWrapper onScroll={onScrollList}>
        {tags.length > 0 && (
          <TagContainer>
            {tags.map((tag, index) => (
              <Tag key={index} id={tag} onClick={onTagClick} green={selectedTag === tag}>
                #{tag}
              </Tag>
            ))}
          </TagContainer>
        )}

        {allPosts.map((post) => (
          <PostCard
            key={post.id}
            post={post}
            onClick={openPost}
            onLiked={onLikeClick}
            isLiked={likedPostsIds.includes(post.id)}
          />
        ))}
      </BlogWrapper>
    </AppContainer>
  );
};
