import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API, COLORS } from '../../../const';
import { AppContainer, Text, LikeButton } from '../../../components';
import { PostType } from '../../../types';
import {
  PostContainer,
  PostImg,
  PostDescription,
  Tag,
  PostImgContainer,
  PostDots,
  Dot,
} from './styled';
import { authProvider } from '../../../utils';

export const Post: FC = () => {
  const [post, setPost] = useState<PostType>();

  const params = useParams();
  const nav = useNavigate();
  const { token, isAuth } = authProvider();

  const dots = post ? [...Array(post.images.length)].map((_, i) => i) : [];

  const getPost = async () => {
    try {
      const response = await fetch(`${API}/posts/${params.id}`);
      if (response.status === 200) {
        const data = await response.json();
        setPost(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setLike = async (postId: string) => {
    try {
      const response = await fetch(`${API}/posts/${postId}/likes`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        getPost();
      }
    } catch (e) {}
  };

  const onLikeClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.id;
    if (isAuth()) {
      setLike(id);
    }
  };

  const onClose = () => {
    nav('/blog');
  };

  useEffect(() => {
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContainer title="Запись" backAction={onClose} hidePanel={!isAuth()}>
      <PostContainer>
        <div>
          {post && !!post.images.length && (
            <PostImgContainer>
              {post.images.map((image) => (
                <PostImg
                  size={post.images[0].resolution[0] < post.images[0].resolution[1] ? 's' : 'm'}
                >
                  <img src={image.src} alt="post img" />
                </PostImg>
              ))}
            </PostImgContainer>
          )}

          <PostDescription>
            <div>
              {post && (
                <LikeButton
                  postId={post.id}
                  count={post.likes}
                  onToggle={onLikeClick}
                  isLiked={!!post.likes}
                />
              )}
              <PostDots>
                {dots.map(() => (
                  <Dot />
                ))}
              </PostDots>
            </div>
            <Text size={14} weight={600} lineHeight={18} pb={12}>
              {post && post.title}
            </Text>
            <Text size={12} weight={18} color={COLORS.text_dark} lineHeight={18}>
              {post && <span dangerouslySetInnerHTML={{ __html: post.content }} />}
            </Text>

            <div>
              {post &&
                post.tags.map((tag) => (
                  <Tag key={tag} green>
                    #{tag}
                  </Tag>
                ))}
            </div>
          </PostDescription>
        </div>
      </PostContainer>
    </AppContainer>
  );
};
