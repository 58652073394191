import React, { FC } from 'react';
import { COLORS } from '../../../const';

type LikeIconProps = {
  clicked: boolean;
};

export const LikeIcon: FC<LikeIconProps> = ({ clicked }) => {
  if (clicked) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M18.4167 7.58333C18.3334 4.75 16.0834 2.5 13.2501 2.5C12.3334 2.5 10.9167 3.16667 10.3334 4.25C10.2501 4.5 9.91675 4.5 9.83342 4.25C9.16675 3.25 7.83342 2.58333 6.83342 2.58333C4.08341 2.58333 1.75008 4.83333 1.66675 7.58333V7.75C1.66675 9.16667 2.25008 10.5 3.25008 11.5C3.25008 11.5 3.25008 11.5 3.25008 11.5833C3.33341 11.6667 7.33342 15.1667 9.16675 16.75C9.66675 17.1667 10.4167 17.1667 10.9167 16.75C12.7501 15.1667 16.6667 11.6667 16.8334 11.5833C16.8334 11.5833 16.8334 11.5833 16.8334 11.5C17.8334 10.5833 18.4167 9.25 18.4167 7.75V7.58333Z"
          fill={`${COLORS.main_green}`}
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.0834 17.3333C9.66675 17.3333 9.16675 17.1667 8.83341 16.8333C3.41675 12.0833 3.33341 12 3.33341 11.9167L3.25008 11.8333C2.25008 10.8333 1.66675 9.41667 1.66675 8V7.83333C1.75008 4.83333 4.16675 2.5 7.16675 2.5C8.08341 2.5 9.33341 3 10.0834 4C10.8334 3 12.1667 2.5 13.0834 2.5C16.0834 2.5 18.4167 4.83333 18.5834 7.83333V8C18.5834 9.5 18.0001 10.8333 17.0001 11.9167L16.9167 12C16.8334 12.0833 16.1667 12.6667 11.4167 16.9167C11.0001 17.1667 10.5834 17.3333 10.0834 17.3333ZM4.58341 11.6667C4.91675 12 6.58341 13.1667 9.66675 15.8333C9.91675 16.0833 10.2501 16.0833 10.5001 15.8333C13.6667 13 15.5001 11.4167 15.9167 11.0833L16.0001 11C16.8334 10.1667 17.2501 9.08333 17.2501 8V7.83333C17.1667 5.5 15.3334 3.75 13.0001 3.75C12.4167 3.75 11.2501 4.16667 10.8334 5.08333C10.6667 5.41667 10.3334 5.58333 10.0001 5.58333C9.66675 5.58333 9.33342 5.41667 9.16675 5.08333C8.75008 4.25 7.66675 3.75 7.00008 3.75C4.75008 3.75 2.83341 5.58333 2.75008 7.83333V8.08333C2.75008 9.16667 3.25008 10.25 4.00008 11L4.58341 11.6667Z"
        fill="black"
      />
    </svg>
  );
};
