export const formatIndex = (index: number): string => {
  let result: string = '';
  const indexStr: string = index.toString();
  let countOfNull = 5 - indexStr.length;

  while (countOfNull > 0) {
    result += '0';
    countOfNull--;
  }

  return result + indexStr;
};

export const formatDate = (date: string): string => {
  let dateStr = '';
  const dateNum = new Date(date).getDate();
  const dateMounth =
    new Date(date).getMonth() + 1 > 10
      ? new Date(date).getMonth() + 1
      : `0${new Date(date).getMonth() + 1}`;
  const dateYear = new Date(date).getFullYear().toString().slice(2);

  const dateHours = new Date(date).getHours();
  const dateMinutes =
    new Date(date).getMinutes() > 10
      ? new Date(date).getMinutes()
      : `0${new Date(date).getMinutes()}`;

  dateStr = `${dateNum}.${dateMounth}.${dateYear} - ${dateHours}:${dateMinutes}`;

  return dateStr;
};

export const formatTime = (date: string): string => {
  const dateHours = new Date(date).getHours();
  const dateMinutes =
    new Date(date).getMinutes() > 10
      ? new Date(date).getMinutes()
      : `0${new Date(date).getMinutes()}`;

  return `${dateHours}:${dateMinutes}`;
};

export const formatTakingDates = (date: string): string => {
  const day = new Date(date).getDate();
  let mounth = new Date(date).toLocaleString('ru', { month: 'long' });

  if (mounth === 'март' || mounth === 'август') {
    mounth += 'a';
  } else {
    mounth = mounth.replace(mounth[mounth.length - 1], 'я');
  }

  return `${day} ${mounth}`;
};

export function formatDateForCalendar(date: Date) {
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  let mounth = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const year = date.getFullYear();

  return `${year}-${mounth}-${day}`;
}
