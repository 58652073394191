import React, { ChangeEvent, useState, useEffect, SyntheticEvent, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { API } from '../../../const';
import { NewPostType, PostType } from '../../../types';
import { authProvider } from '../../../utils';
import { AppButton, AppContainer, CloseIcon } from '../../../components';
import {
  Input,
  Wrapper,
  ButtonWrapper,
  TagContainer,
  Tag,
  FileInput,
  PreviewContainer,
  Preview,
  DeleteButton,
} from './styled';

import imagePath from '../../../assets/icons/image.svg';
import deletePath from '../../../assets/icons/close.svg';

export const NewPostPage = () => {
  const [allTags, setAllTags] = useState<string[]>([]);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [newTags, setNewTags] = useState<string[]>([]);

  const [titleValue, setTitleValue] = useState('');
  const [contentValue, setContentValue] = useState('');
  const [tagInputValue, setTagInputValue] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<(File | null)[]>([]);

  const navigate = useNavigate();
  const { token } = authProvider();

  const onClose = () => {
    navigate('/blog');
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
  };

  const onChangeTextarea = (event: any, editor: any) => {
    const data = editor.getData();
    setContentValue(data);
  };

  const onChangeTagInput = (e: ChangeEvent<HTMLInputElement>) => {
    setTagInputValue(e.target.value);
  };

  const onKeyupTagInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      setSelectedTags((prev) => [...prev, tagInputValue.trim().replace('#', '')]);
      setNewTags((prev) => [...prev, tagInputValue.trim().replace('#', '')]);
      setTagInputValue('');
    }
  };

  const onTagClick = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = (e.target as Element).id;
    if (selectedTags.indexOf(id) === -1) {
      setSelectedTags((prev) => [...prev, id]);
    } else {
      setSelectedTags((prev) => prev.filter((item) => item !== id));
    }
  };

  const onUpload = () => {
    const input = document.getElementById('upload_post_img');
    if (input) {
      input.click();
    }
  };

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const files: File[] = new Array<File>();
    if (e.target.files?.length) {
      const length = e.target.files.length;
      let i = 0;
      while (i < length) {
        files[i] = e.target.files.item(i) as File;
        i++;
      }
      setUploadedFiles((prev) => [...prev, ...files]);
    }
  };

  const onDeleteFile = (e: SyntheticEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.id;
    setUploadedFiles((prev) => prev.filter((item) => item?.name !== name));
  };

  const getTags = async () => {
    try {
      const response = await fetch(`${API}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data: string[] = await response.json();
        setAllTags(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postNewPostImg = async (id: string) => {
    try {
      const formData = new FormData();
      if (uploadedFiles) {
        uploadedFiles.forEach((file) => {
          if (file) {
            formData.append('files', file);
          }
        });
      }
      await fetch(`${API}/posts/${id}/files`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const postNewPost = async () => {
    try {
      const data: NewPostType = {
        title: titleValue,
        content: contentValue,
        tags: selectedTags,
      };
      const response = await fetch(`${API}/posts`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.status === 200 && uploadedFiles.length) {
        const post: PostType = await response.json();
        postNewPostImg(post.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContainer title="Новая запись" icon={<CloseIcon />} action={onClose}>
      <div style={{ overflow: 'scroll' }}>
        {!!uploadedFiles.length && (
          <PreviewContainer>
            {uploadedFiles.map((file) => (
              <Preview key={file?.name}>
                <img src={URL.createObjectURL(file as File)} alt="uploaded file" />
                <DeleteButton id={file?.name} onClick={onDeleteFile}>
                  <img src={deletePath} alt="delete" />
                </DeleteButton>
              </Preview>
            ))}
          </PreviewContainer>
        )}
        <AppButton
          title="Добавить фото"
          fullWidth
          icon={<img src={imagePath} alt="img" />}
          onClick={onUpload}
        />
        <FileInput
          id="upload_post_img"
          type="file"
          multiple={true}
          accept="image/*"
          onChange={onChangeFile}
        />
        <Wrapper>
          <Input name="title" value={titleValue} onChange={onChangeInput} placeholder="Заголовок" />
          <div className="App">
            <CKEditor editor={ClassicEditor} data={contentValue} onChange={onChangeTextarea} />
          </div>
          <Input
            name="tag"
            value={tagInputValue}
            onChange={onChangeTagInput}
            onKeyUp={onKeyupTagInput}
            placeholder="#теги"
          />
          {allTags.length > 0 && (
            <TagContainer>
              {allTags.concat(newTags).map((tag, index) => (
                <Tag
                  key={index}
                  id={tag}
                  onClick={onTagClick}
                  green={selectedTags.indexOf(tag) !== -1}
                >
                  #{tag}
                </Tag>
              ))}
            </TagContainer>
          )}
        </Wrapper>
      </div>
      <ButtonWrapper>
        <AppButton
          title="Опубликовать"
          fullWidth
          onClick={postNewPost}
          disabled={!titleValue && !contentValue}
        />
      </ButtonWrapper>
    </AppContainer>
  );
};
