import React, { FC, PropsWithChildren } from 'react';
import { Text } from '../Text';
import { Panel } from './Panel';
import { DesktopPanel } from './DesktopPanel';
import { BackIcon } from '../Icons';
import { Container, Wrapper, TitleWrapper, BackButton } from './styled';

type AppContainerProps = {
  title: string;
  icon?: JSX.Element;
  action?: () => void;
  hidePanel?: boolean;
  backAction?: () => void;
};

export const AppContainer: FC<PropsWithChildren<AppContainerProps>> = ({
  title,
  icon,
  action,
  hidePanel,
  backAction,
  children,
}) => {
  const isMobile = window.screen.availWidth < 1024;

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          {title && (
            <Text size={20} weight={700}>
              {title}
            </Text>
          )}
          {!!icon && <div onClick={action}>{icon}</div>}
        </TitleWrapper>
        {children}
      </Wrapper>

      {backAction && (
        <BackButton onClick={backAction}>
          <BackIcon />
        </BackButton>
      )}
      {!hidePanel && isMobile && <Panel />}
      {!hidePanel && !isMobile && <DesktopPanel />}
    </Container>
  );
};
