import React, { FC, SyntheticEvent } from 'react';
import { PostType } from '../../../types';
import { LikeButton, Text } from '../../../components';
import { PostCardContainer, PostCardImg, PostDescription, SmallTag } from './styled';

type PostCardProps = {
  post: PostType;
  onClick: (e: SyntheticEvent<HTMLDivElement>) => void;
  onLiked: (e: SyntheticEvent<HTMLButtonElement>) => void;
  isLiked: boolean;
};

export const PostCard: FC<PostCardProps> = ({ post, onClick, onLiked, isLiked }) => {
  const content = post.content?.split('&')[0].slice(0, 120);

  return (
    <PostCardContainer id={post.alias} onClick={onClick}>
      {!!post.images?.length && (
        <PostCardImg size={post.images[0].resolution[0] < post.images[0].resolution[1] ? 's' : 'm'}>
          <img src={post.images[0].src} alt="post img" />
        </PostCardImg>
      )}

      <PostDescription>
        <LikeButton postId={post.id} count={post.likes} onToggle={onLiked} isLiked={isLiked} />
        <Text size={14} weight={600} lineHeight={18}>
          {post.title}
        </Text>
        <Text size={12} weight={18} color="#787D93" lineHeight={18}>
          <span dangerouslySetInnerHTML={{ __html: content + '...' }} />
        </Text>

        <div>
          {post.tags?.map((tag) => (
            <SmallTag key={tag}>#{tag}</SmallTag>
          ))}
        </div>
      </PostDescription>
    </PostCardContainer>
  );
};
