import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const BlogWrapper = styled.div`
  margin-top: 12px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Tag = styled.div.withConfig({
  shouldForwardProp: (p) => !['green'].includes(p),
})<{
  green?: boolean;
}>`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.green ? `${COLORS.main_green}` : '#cbcedf')};
  border-radius: 24px;
  color: ${(props) => (props.green ? '#35373F' : '#787d93')};
  font-size: 12px;
  cursor: pointer;
`;

export const SmallTag = styled.div`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d4f0e1;
  border-radius: 24px;
  color: #787d93;
  font-size: 10px;
  cursor: pointer;
`;

export const PostCardContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${COLORS.main_white};
`;

export const PostCardImg = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 's' | 'm';
}>`
  height: ${(props) => (props.size === 's' ? '1024px' : '576px')};
  display: flex;

  & img {
    width: 100%;
    object-fit: contain;
    border-radius: 12px 12px 0 0;
  }
`;

export const PostDescription = styled.div`
  padding: 8px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  & div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const PostContainer = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.main_background}
  color: #35373f;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: 1024px) {
    max-width: 733px;
  }
`;

export const PostImgContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const PostImg = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 's' | 'm';
}>`
  min-width: 100%;
  height: ${(props) => (props.size === 's' ? 'auto' : '576px')};
  margin-top: 12px;
  display: flex;
  flex-wrap: nowrap;

  & img {
    width: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    margin-right: 20px;
  }
`;

export const PostDots = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #babdcc;
`;
