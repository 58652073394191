import { TabsType, PILL_UNIT, DAYTIME, CourseType } from './types';

export const API =
  window.location.host.includes('3000') || window.location.host.includes('dev')
    ? 'https://dev.doctorly.ru/api'
    : 'https://doctorly.ru/api';

export const COLORS = {
  main_background: '#EDEFF5',
  main_green: '#68C378',
  main_white: '#F8F8F8',
  main_grey: '#CFD4F1',
  main_grey1: '#BABDCC',
  main_red: '#E74D3F',
  text_dark: '#35373F',
  text_grey: '#959AB4',
};

export const TABS: TabsType = {
  cardio: 'Кардио',
  therapy: 'Терапия',
};

export const AGE_OPTIONS = Array.from({ length: 80 }, (v, i) => ({
  id: i + 1,
  score: 0,
  answer: `${i + 1}`,
  flag: 'none',
})).slice(17);

export const REGION_OPTIONS = [
  {
    id: 1,
    score: 0,
    answer: 'Республика Адыгея',
    flag: 'none',
  },
  {
    id: 2,
    score: 0,
    answer: 'Республика Башкортостан',
    flag: 'none',
  },
  {
    id: 3,
    score: 0,
    answer: 'Республика Бурятия',
    flag: 'none',
  },
  {
    id: 4,
    score: 0,
    answer: 'Республика Алтай',
    flag: 'none',
  },
  {
    id: 5,
    score: 0,
    answer: 'Республика Дагестан',
    flag: 'none',
  },
  {
    id: 6,
    score: 0,
    answer: 'Республика Ингушетия',
    flag: 'none',
  },
  {
    id: 7,
    score: 0,
    answer: 'Кабардино-Балкарская Республика',
    flag: 'none',
  },
  {
    id: 8,
    score: 0,
    answer: 'Республика Калмыкия',
    flag: 'none',
  },
  {
    id: 9,
    score: 0,
    answer: 'Карачаево-Черкесская Республика',
    flag: 'none',
  },
  {
    id: 10,
    score: 0,
    answer: 'Республика Карелия',
    flag: 'none',
  },
  {
    id: 11,
    score: 0,
    answer: 'Республика Коми',
    flag: 'none',
  },
  {
    id: 12,
    score: 0,
    answer: 'Республика Марий Эл',
    flag: 'none',
  },
  {
    id: 13,
    score: 0,
    answer: 'Республика Мордовия',
    flag: 'none',
  },
  {
    id: 14,
    score: 0,
    answer: 'Республика Саха (Якутия)',
    flag: 'none',
  },
  {
    id: 15,
    score: 0,
    answer: 'Республика Северная Осетия - Алания',
    flag: 'none',
  },
  {
    id: 16,
    score: 0,
    answer: 'Республика Татарстан',
    flag: 'none',
  },
  {
    id: 17,
    score: 0,
    answer: 'Республика Тыва',
    flag: 'none',
  },
  {
    id: 18,
    score: 0,
    answer: 'Удмуртская Республика',
    flag: 'none',
  },
  {
    id: 19,
    score: 0,
    answer: 'Республика Хакасия',
    flag: 'none',
  },
  {
    id: 20,
    score: 0,
    answer: 'Чеченская Республика',
    flag: 'none',
  },
  {
    id: 21,
    score: 0,
    answer: 'Чувашская Республика',
    flag: 'none',
  },
  {
    id: 22,
    score: 0,
    answer: 'Алтайский край',
    flag: 'none',
  },
  {
    id: 23,
    score: 0,
    answer: 'Краснодарский край',
    flag: 'none',
  },
  {
    id: 24,
    score: 0,
    answer: 'Красноярский край',
    flag: 'none',
  },
  {
    id: 25,
    score: 0,
    answer: 'Приморский край',
    flag: 'none',
  },
  {
    id: 26,
    score: 0,
    answer: 'Ставропольский край',
    flag: 'none',
  },
  {
    id: 27,
    score: 0,
    answer: 'Хабаровский край',
    flag: 'none',
  },
  {
    id: 28,
    score: 0,
    answer: 'Амурская область',
    flag: 'none',
  },
  {
    id: 29,
    score: 0,
    answer: 'Архангельская область',
    flag: 'none',
  },
  {
    id: 30,
    score: 0,
    answer: 'Астраханская область',
    flag: 'none',
  },
  {
    id: 31,
    score: 0,
    answer: 'Белгородская область',
    flag: 'none',
  },
  {
    id: 32,
    score: 0,
    answer: 'Брянская область',
    flag: 'none',
  },
  {
    id: 33,
    score: 0,
    answer: 'Владимирская область',
    flag: 'none',
  },
  {
    id: 34,
    score: 0,
    answer: 'Волгоградская область',
    flag: 'none',
  },
  {
    id: 35,
    score: 0,
    answer: 'Вологодская область',
    flag: 'none',
  },
  {
    id: 36,
    score: 0,
    answer: 'Воронежская область',
    flag: 'none',
  },
  {
    id: 37,
    score: 0,
    answer: 'Ивановская область',
    flag: 'none',
  },
  {
    id: 38,
    score: 0,
    answer: 'Иркутская область',
    flag: 'none',
  },
  {
    id: 39,
    score: 0,
    answer: 'Калининградская область',
    flag: 'none',
  },
  {
    id: 40,
    score: 0,
    answer: 'Калужская область',
    flag: 'none',
  },
  {
    id: 41,
    score: 0,
    answer: 'Камчатский край',
    flag: 'none',
  },
  {
    id: 42,
    score: 0,
    answer: 'Кемеровская область - Кузбасс',
    flag: 'none',
  },
  {
    id: 43,
    score: 0,
    answer: 'Кировская область',
    flag: 'none',
  },
  {
    id: 44,
    score: 0,
    answer: 'Костромская область',
    flag: 'none',
  },
  {
    id: 45,
    score: 0,
    answer: 'Курганская область',
    flag: 'none',
  },
  {
    id: 46,
    score: 0,
    answer: 'Курская область',
    flag: 'none',
  },
  {
    id: 47,
    score: 0,
    answer: 'Ленинградская область',
    flag: 'none',
  },
  {
    id: 48,
    score: 0,
    answer: 'Липецкая область',
    flag: 'none',
  },
  {
    id: 49,
    score: 0,
    answer: 'Магаданская область',
    flag: 'none',
  },
  {
    id: 50,
    score: 0,
    answer: 'Московская область',
    flag: 'none',
  },
  {
    id: 51,
    score: 0,
    answer: 'Мурманская область',
    flag: 'none',
  },
  {
    id: 52,
    score: 0,
    answer: 'Нижегородская область',
    flag: 'none',
  },
  {
    id: 53,
    score: 0,
    answer: 'Новгородская область',
    flag: 'none',
  },
  {
    id: 54,
    score: 0,
    answer: 'Новосибирская область',
    flag: 'none',
  },
  {
    id: 55,
    score: 0,
    answer: 'Омская область',
    flag: 'none',
  },
  {
    id: 56,
    score: 0,
    answer: 'Оренбургская область',
    flag: 'none',
  },
  {
    id: 57,
    score: 0,
    answer: 'Орловская область',
    flag: 'none',
  },
  {
    id: 58,
    score: 0,
    answer: 'Пензенская область',
    flag: 'none',
  },
  {
    id: 59,
    score: 0,
    answer: 'Пермский край',
    flag: 'none',
  },
  {
    id: 60,
    score: 0,
    answer: 'Псковская область',
    flag: 'none',
  },
  {
    id: 61,
    score: 0,
    answer: 'Ростовская область',
    flag: 'none',
  },
  {
    id: 62,
    score: 0,
    answer: 'Рязанская область',
    flag: 'none',
  },
  {
    id: 63,
    score: 0,
    answer: 'Самарская область',
    flag: 'none',
  },
  {
    id: 64,
    score: 0,
    answer: 'Саратовская область',
    flag: 'none',
  },
  {
    id: 65,
    score: 0,
    answer: 'Сахалинская область',
    flag: 'none',
  },
  {
    id: 66,
    score: 0,
    answer: 'Свердловская область',
    flag: 'none',
  },
  {
    id: 67,
    score: 0,
    answer: 'Смоленская область',
    flag: 'none',
  },
  {
    id: 68,
    score: 0,
    answer: 'Тамбовская область',
    flag: 'none',
  },
  {
    id: 69,
    score: 0,
    answer: 'Тверская область',
    flag: 'none',
  },
  {
    id: 70,
    score: 0,
    answer: 'Томская область',
    flag: 'none',
  },
  {
    id: 71,
    score: 0,
    answer: 'Тульская область',
    flag: 'none',
  },
  {
    id: 72,
    score: 0,
    answer: 'Тюменская область',
    flag: 'none',
  },
  {
    id: 73,
    score: 0,
    answer: 'Ульяновская область',
    flag: 'none',
  },
  {
    id: 74,
    score: 0,
    answer: 'Челябинская область',
    flag: 'none',
  },
  {
    id: 75,
    score: 0,
    answer: 'Забайкальский край',
    flag: 'none',
  },
  {
    id: 76,
    score: 0,
    answer: 'Ярославская область',
    flag: 'none',
  },
  {
    id: 77,
    score: 0,
    answer: 'город Москва',
    flag: 'none',
  },
  {
    id: 78,
    score: 0,
    answer: 'город Санкт-Петербург',
    flag: 'none',
  },
  {
    id: 79,
    score: 0,
    answer: 'Еврейская Автономная область',
    flag: 'none',
  },
  {
    id: 80,
    score: 0,
    answer: 'Донецкая Народная Республика',
    flag: 'none',
  },
  {
    id: 81,
    score: 0,
    answer: 'Луганская Народная Республика',
    flag: 'none',
  },
  {
    id: 82,
    score: 0,
    answer: 'Республика Крым',
    flag: 'none',
  },
  {
    id: 83,
    score: 0,
    answer: 'Ненецкий Автономный округ',
    flag: 'none',
  },
  {
    id: 84,
    score: 0,
    answer: 'Херсонская область',
    flag: 'none',
  },
  {
    id: 85,
    score: 0,
    answer: 'Запорожская область',
    flag: 'none',
  },
  {
    id: 86,
    score: 0,
    answer: 'Ханты-Мансийский Автономный округ - Югра',
    flag: 'none',
  },
  {
    id: 87,
    score: 0,
    answer: 'Чукотский Автономный округ',
    flag: 'none',
  },
  {
    id: 89,
    score: 0,
    answer: 'Ямало-Ненецкий Автономный округ',
    flag: 'none',
  },
  {
    id: 92,
    score: 0,
    answer: 'город Севастополь',
    flag: 'none',
  },
  {
    id: 94,
    score: 0,
    answer: 'город Байконур',
    flag: 'none',
  },
];

export const UNIT_OPTIONS = {
  [PILL_UNIT.PILL]: 'Таблетки',
  [PILL_UNIT.CAPSULE]: 'Капсулы',
  [PILL_UNIT.INJECTION]: 'Уколы',
};

export const DAYTIME_OPTIONS = {
  [DAYTIME.BEFORE]: 'после еды',
  [DAYTIME.AFTER]: 'до еды',
  [DAYTIME.NO_MATTER]: 'не указано',
};

export const QUESTIONS = [
  { id: 1, title: 'Рост', options: [], type: 'decimal', page: 1 },
  { id: 2, title: 'Вес', options: [], type: 'decimal', page: 1 },
  { id: 3, title: 'ИМТ', options: [], type: 'decimal', page: 1 },
  { id: 4, title: 'Возраст', options: [], type: 'decimal', page: 1 },
  {
    id: 5,
    title: 'Пол',
    options: [
      { flag: 'none', id: 1, answer: 'M', score: 0 },
      { flag: 'none', id: 2, answer: 'Ж', score: 0 },
    ],
    type: 'select',
    page: 1,
  },
  {
    id: 6,
    picker_type: 'region',
    title: 'Регион проживания',
    type: 'picker',
    options: [],
    page: 1,
  },
  {
    id: 7,
    title: 'У вас отягощена наследственность?',
    options: [
      { flag: 'none', id: 3, answer: 'Да, повышенное давление', score: 1 },
      { flag: 'none', id: 4, answer: 'Да, стенокардия', score: 1 },
      {
        flag: 'none',
        id: 5,
        answer: 'Да, инфаркт миокарда или инсульт у матери ранее 65 лет, у отца ранее 55 лет',
        score: 2,
      },
      { flag: 'none', id: 6, answer: 'Нет', score: 0 },
    ],
    description:
      'Повышенное давление, стенокардия, инфаркт миокарда, инсульт у матери ранее 65 лет, у отца ранее 55 лет',
    type: 'multiselect',
    page: 2,
  },
  {
    id: 8,
    title: 'Вы курите?',
    options: [
      { flag: 'none', id: 7, answer: 'Да', score: 1 },
      { flag: 'none', id: 8, answer: 'Нет', score: 0 },
    ],
    type: 'select',
    page: 3,
  },
  {
    id: 9,
    title: 'Вы страдаете сахарным диабетом?',
    options: [
      { flag: 'none', id: 9, answer: 'Да', score: 2 },
      { flag: 'none', id: 10, answer: 'Нет', score: 0 },
    ],
    type: 'select',
    page: 4,
  },
  {
    id: 10,
    title: 'Как часто Вы употребляете алкоголь?',
    options: [
      { flag: 'none', id: 11, answer: 'Менее 1 порции в неделю или не употребляю вовсе', score: 0 },
      { flag: 'none', id: 12, answer: '1-14 порций в неделю', score: 0 },
      { flag: 'none', id: 13, answer: 'Свыше 14 порций в неделю', score: 1 },
    ],
    description:
      '1 порция = 40 г крепкого алкоголя (свыше 15 градусов) = 150 мл вина = 330-350 мл пива/сидра',
    type: 'select',
    page: 5,
  },
  {
    id: 11,
    title: 'Были ли у Вас «сосудистые события»?',
    options: [
      { flag: 'none', id: 14, answer: 'Да', score: 2 },
      { flag: 'none', id: 15, answer: 'Нет', score: 0 },
    ],
    description: 'Инфаркт, инсульт, транзиторная ишемическая атака',
    type: 'select',
    page: 6,
  },
  {
    id: 12,
    title: 'Тратите ли Вы более 30 мин на ходьбу в умеренном или быстром темпе?',
    options: [
      { flag: 'none', id: 16, answer: 'Да', score: 0 },
      { flag: 'none', id: 17, answer: 'Нет', score: 1 },
    ],
    description: 'Включая дорогу до места работы и обратно',
    type: 'select',
    page: 7,
  },
  {
    id: 13,
    title:
      'Употребляете ли Вы ежедневно около 400 гр (4-5 порций) фруктов и овощей (не считая картофель)?',
    options: [
      { flag: 'none', id: 18, answer: 'Да', score: 0 },
      { flag: 'none', id: 19, answer: 'Нет', score: 1 },
    ],
    type: 'select',
    page: 8,
  },
  {
    id: 14,
    title: 'Подсаливаете ли Вы приготовленную пищу, не пробуя ее?',
    options: [
      { flag: 'none', id: 20, answer: 'Да', score: 1 },
      { flag: 'none', id: 21, answer: 'Нет', score: 0 },
    ],
    type: 'select',
    page: 9,
  },
  {
    id: 15,
    title: 'У Вас были случаи тромбозов?',
    options: [
      { flag: 'none', id: 22, answer: 'Да', score: 1 },
      { flag: 'none', id: 23, answer: 'Нет', score: 0 },
    ],
    type: 'select',
    page: 10,
  },
  {
    id: 16,
    title: 'Какова продолжительность вашего сна в день?',
    options: [
      { flag: 'none', id: 24, answer: 'Менее 7 часов', score: 1 },
      { flag: 'none', id: 25, answer: 'Около 7-8 часов', score: 0 },
      { flag: 'none', id: 26, answer: 'Более 9 часов', score: 1 },
    ],
    type: 'select',
    page: 11,
  },
  {
    id: 17,
    title: 'Вы испытываете психо-эмоциональное напряжение (стресс), вам бывает не по себе?',
    options: [
      { flag: 'none', id: 27, answer: 'Все время (ежедневно происходящие события)', score: 2 },
      { flag: 'none', id: 28, answer: 'Часто (еженедельно происходящие события)', score: 1 },
      {
        flag: 'none',
        id: 29,
        answer: 'Время от времени, иногда (события случаются несколько раз за месяц и реже)',
        score: 0,
      },
      { flag: 'none', id: 30, answer: 'Cовсем не испытываю', score: 0 },
    ],
    type: 'select',
    page: 12,
  },
  {
    id: 18,
    title:
      'Перечислите принимаемые препараты на регулярное основе, нерегулярно принимаемые, но с частотой приема не реже 1-2 раз в неделю.',
    options: [],
    type: 'text',
    page: 13,
  },
  {
    id: 19,
    title: 'За последние 4 недели у Вас были следующие симптомы:',
    options: [
      { flag: 'red', id: 31, answer: 'Боль за грудиной, чувство стеснения в груди', score: 20 },
      {
        flag: 'none',
        id: 32,
        answer: 'Перебои в работе сердца/эпизоды учащенного сердцебиения',
        score: 1,
      },
      { flag: 'red', id: 33, answer: 'Потеря сознания', score: 20 },
      { flag: 'none', id: 34, answer: 'Головокружение', score: 1 },
      { flag: 'none', id: 35, answer: 'Одышка при физической нагрузке/в покое', score: 1 },
      { flag: 'none', id: 36, answer: 'Ничего из перечисленного', score: 0 },
    ],
    type: 'multiselect',
    page: 14,
  },
  {
    id: 20,
    title:
      'За прошедшие 4 недели, как часто, Вы испытывали боли в грудной клетке, чувство стеснения в груди?',
    options: [
      { flag: 'red', id: 37, answer: 'Каждый день', score: 20 },
      { flag: 'none', id: 38, answer: '1-2 раза в раза в неделю', score: 1 },
      { flag: 'none', id: 39, answer: 'Ни разу за последние 4 недели', score: 0 },
    ],
    type: 'select',
    page: 15,
  },
  {
    id: 21,
    title:
      'За прошедшие 4 недели, как часто Вы прибегали к препаратам для дополнительного снижения артериального давления?',
    options: [
      { flag: 'red', id: 40, answer: 'Ежедневно', score: 20 },
      { flag: 'none', id: 41, answer: '1-2 раза в раза в неделю', score: 1 },
      { flag: 'none', id: 42, answer: 'Ни разу за последние 4 недели', score: 0 },
    ],
    type: 'select',
    page: 16,
  },
  {
    id: 22,
    title:
      'За прошедшие 4 недели, как часто Вы ощущали перебои в работе сердца, ускоренное сердцебиение?',
    options: [
      { flag: 'yellow', id: 43, answer: 'Ежедневно', score: 10 },
      { flag: 'none', id: 44, answer: '1-2 раза в раза в неделю', score: 1 },
      { flag: 'none', id: 45, answer: 'Ни разу за последние 4 недели', score: 0 },
    ],
    type: 'select',
    page: 17,
  },
  {
    id: 23,
    title: 'За последние 4 недели Вы отмечали появление/усиление отеков нижних конечностей?',
    options: [
      { flag: 'yellow', id: 46, answer: 'Да', score: 5 },
      { flag: 'none', id: 47, answer: 'Практически нет', score: 1 },
      { flag: 'none', id: 48, answer: 'У меня нет отеков', score: 0 },
    ],
    type: 'select',
    page: 18,
  },
  {
    id: 24,
    title: 'Цифры Вашего артериального давления при ежедневном измерении:',
    options: [
      { flag: 'none', id: 49, answer: 'Ниже 140/90 мм рт ст', score: 0 },
      { flag: 'yellow', id: 50, answer: 'Выше 140/90 мм рт ст', score: 5 },
      { flag: 'none', id: 51, answer: 'Я не измеряю свое артериальное давление', score: 1 },
    ],
    type: 'select',
    page: 19,
  },
  {
    id: 25,
    title: 'За последние 4 недели, как часто Вы испытывали головокружение?',
    options: [
      { flag: 'yellow', id: 52, answer: 'Ежедневно', score: 5 },
      { flag: 'none', id: 53, answer: '1-2 раза в раза в неделю', score: 1 },
      { flag: 'none', id: 54, answer: 'Меня не беспокоят головокружения', score: 0 },
    ],
    type: 'select',
    page: 20,
  },
  {
    id: 26,
    title: 'За последние 4 недели, как часто вы ощущаете общую слабость?',
    options: [
      { flag: 'yellow', id: 55, answer: 'Ежедневно', score: 5 },
      { flag: 'none', id: 56, answer: '1-2 раза в раза в неделю', score: 1 },
      { flag: 'none', id: 57, answer: 'Общая слабость меня не беспокоит', score: 0 },
    ],
    type: 'select',
    page: 21,
  },
  {
    id: 27,
    title: 'Вы можете пройти без одышки:',
    options: [
      { flag: 'yellow', id: 58, answer: 'Менее 200 метров/ менее 1 лестничного пролета', score: 5 },
      { flag: 'none', id: 59, answer: 'Более 500 метров/ более 3 лестничных пролетов', score: 1 },
      { flag: 'yellow', id: 60, answer: 'Одышка бывает и в покое', score: 5 },
      { flag: 'none', id: 61, answer: 'Одышка меня не беспокоит', score: 0 },
    ],
    type: 'select',
    page: 22,
  },
];

export const PILLS: CourseType[] = [
  {
    id: '1',
    drug_title: 'Сонован',
    mera: 1,
    unit: PILL_UNIT.PILL,
    daytime: DAYTIME.AFTER,
    comment: '',
    date_start: '2023-03-06T10:38:10.779Z',
    date_end: '2023-11-30',
  },
  {
    id: '2',
    drug_title: 'Сонован',
    mera: 1,
    unit: PILL_UNIT.CAPSULE,
    daytime: DAYTIME.BEFORE,
    comment: '',
    date_start: '2023-11-06T15:38:10.779Z',
    date_end: '2023-11-30',
  },
];
