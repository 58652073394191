import React, { FC } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../const';

import defaultAvatarPath from '../../assets/icons/avatar.svg';

const AvatarWrapper = styled.div.withConfig({
  shouldForwardProp: (p) => !['size'].includes(p),
})<{
  size?: 'small' | 'large';
}>`
  width: ${(props) => (props.size === 'large' ? '66px' : '48px')};
  height: ${(props) => (props.size === 'large' ? '66px' : '48px')};
  margin-right: 10px;
  border-radius: ${(props) => (props.size === 'large' ? '33px' : '24px')};
  border: 2px solid ${COLORS.main_green};

  & img {
    width: ${(props) => (props.size === 'large' ? '62px' : '44px')};
    height: ${(props) => (props.size === 'large' ? '62px' : '44px')};
    border-radius: ${(props) => (props.size === 'large' ? '31px' : '22px')};
  }
`;

type AvatarProps = {
  size: 'small' | 'large';
  path: string;
};

export const Avatar: FC<AvatarProps> = ({ size, path }) => {
  return (
    <AvatarWrapper size={size}>
      <img src={path ? path : defaultAvatarPath} alt="avatar" />
    </AvatarWrapper>
  );
};
