import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const Wrapper = styled.div`
  margin-top: 12px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PreviewContainer = styled.div`
  min-height: 200px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: scroll;

  & img {
    max-width: 190px;
    height: 190px;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const Preview = styled.div`
  position: relative;
  max-width: 190px;
  height: 190px;
`;

export const Input = styled.input`
  padding: 8px;
  background-color: ${COLORS.main_background};
  font-family: 'Lato', sans-serif;
  border-bottom: 1px solid #cbcedf;

  &::placeholder {
    color: #959ab4;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 100px;
  width: 1024px;
  height: 60px;
  display: flex;
  align-items: flex-end;
  background-color: ${COLORS.main_background};
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Tag = styled.div.withConfig({
  shouldForwardProp: (p) => !['green'].includes(p),
})<{
  green?: boolean;
}>`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.green ? `${COLORS.main_green}` : '#cbcedf')};
  border-radius: 24px;
  color: ${(props) => (props.green ? '#35373F' : '#787d93')};
  font-size: 12px;
  cursor: pointer;
`;

export const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;

  & img {
    width: 10px;
    height: 10px;
  }
`;
